<template>
  <q-dialog v-model="showChangePassword" persistent>
    <q-card style="width: 500px">
      <q-bar class="bg-primary text-white">
        <q-icon name="fas fa-key" />
        <span class="q-px-md">修改密码</span>
        <q-space />

        <q-btn dense flat icon="close" v-close-popup>
          <q-tooltip>关闭</q-tooltip>
        </q-btn>
      </q-bar>

      <q-card-section class="q-pa-md">
        <q-input prefix="用户名:" v-model="userName" readonly />
        <q-input
          prefix="原密码:"
          v-model="queryParm.Password"
          type="password"
        />
        <q-input
          prefix="新密码:"
          v-model="queryParm.NewPassword"
          type="password"
        />
        <q-input
          prefix="确认密码:"
          v-model="queryParm.ConfirmPassword"
          type="password"
        />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          label="取消"
          color="primary"
          v-close-popup
          :loading="loading"
        />
        <q-btn
          flat
          label="确定"
          color="primary"
          @click="onOK"
          :loading="loading"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import dlg from "@/config/dialog";
export default {
  computed: {
    ...mapState("SysUser", ["ShowChangePassword"]),
    ...mapGetters("appUser", ["userName"]),

    showChangePassword: {
      get() {
        return this.ShowChangePassword;
      },
      set(val) {
        this.$store.commit("SysUser/setShowChangePassword", val);
      },
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.queryParm.Password = "";
          this.queryParm.NewPassword = "";
          this.queryParm.ConfirmPassword = "";
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      queryParm: {
        UserID: this.$store.getters["appUser/userID"],
        Password: "",
        NewPassword: "",
        ConfirmPassword: "",
      },
    };
  },
  methods: {
    ...mapActions("SysUser", ["actChangePassword"]),
    ...mapActions("appUser", ["actUserLogout"]),
    onOK() {
      this.queryParm.UserID = this.$store.getters["appUser/userID"];
      this.loading = true;
      this.actChangePassword(this.queryParm)
        .then((res) => {
          if (res.Code == 0) {
            dlg.alert("修改密码成功，系统将重新登录").onOk(() => {
              this.actUserLogout();
              location.reload();
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>