<template>
  <q-card :bordered="false">
    <q-card-section horizontal>
      <q-card-section v-if="currentUser">
        <div class="text-h5">
          <span>{{ userName }}</span>
          <q-icon
            :color="currentUser.Sex == '男' ? 'primary' : 'pink'"
            name="account_circle"
          />
        </div>
        <div class="text-subtitle2 text-grey">
          {{ currentUser.UserCode }}
        </div>
        <q-separator />

        <div class="text-subtitle2 text-grey">
          <q-avatar icon="contact_phone" rounded />{{ currentUser.Phone }}
          <q-btn
            flat
            dense
            icon="edit"
            class="q-ml-sm text-primary"
            @click="onEditModileNo"
          />
        </div>
        <div class="text-subtitle2 text-grey">
          <q-avatar icon="contact_mail" rounded />
          <span>{{ currentUser.Email }}</span>
          <q-btn
            flat
            dense
            icon="edit"
            class="q-ml-sm text-primary"
            @click="onEditEmail"
          />
        </div>
      </q-card-section>
    </q-card-section>
    <q-separator />
    <q-card-actions align="around">
      <q-btn-dropdown
        dense
        flat
        color="primary"
        icon="location_city"
        label="切换机构"
      >
        <q-list dense>
          <q-item
            clickable
            v-close-popup
            v-for="d in authHosps"
            :key="d.HospID"
            @click="onChangeHospID(d)"
          >
            <q-item-section avatar>
              <q-avatar
                size="24px"
                icon="location_city"
                color="light-blue-6"
                text-color="white"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>[{{ d.HospMICode }}] {{d.HospID}}-{{ d.HospName }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
      <q-separator vertical />
      <q-btn
        dense
        flat
        color="primary"
        icon="vpn_key"
        @click="onChangePassword"
      >
        <q-tooltip> 修改登录密码 </q-tooltip>
      </q-btn>
      <q-separator vertical />
      <q-btn dense flat color="primary" icon="exit_to_app" @click="onLogout">
        <q-tooltip> 注销用户并退出系统 </q-tooltip>
      </q-btn>
    </q-card-actions>
    <ChangePasswordView />
  </q-card>

  <!-- <q-card class="q-mt-md">
    <q-card-section>
      <div class="text-h5">
        <span>设置</span>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-section align="around">
      <q-toggle v-model="multiPage" label="多页模式" />
    </q-card-section>
  </q-card> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ChangePasswordView from "@/views/gy/base/user/changePassword.vue";
export default {
  components: {
    ChangePasswordView,
  },
  computed: {
    ...mapGetters("appUser", ["userName", "authHosps", "currentUser"]),
    multiPage: {
      get() {
        return this.$store.getters["appSetting/multiPage"];
      },
      set(val) {
        this.$store.commit("appSetting/setMultiPage", val);
        setTimeout(()=>{
          location.reload()
        },500)
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("appUser", [
      "actUserLogout",
      "actSetCurrentHospID",
      "actChangePassword",
    ]),
    onLogout() {
      this.actUserLogout();
      this.$router.replace({name:'login'});
    },
    //修改电话
    onEditModileNo() {},
    //修改邮箱
    onEditEmail() {},
    //切换机构
    onChangeHospID(e) {
      this.actSetCurrentHospID(e.HospID);
      location.reload(true);
    },
    //修改密码
    onChangePassword() {
      this.$store.commit("SysUser/setShowChangePassword", true);
    },
  },
};
</script>